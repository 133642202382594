<template>
  <div class="recruit">
    
  </div>
</template>

<script>

export default {
  name: 'recruit',
  data(){
    window.location.href('https://www.hotjob.cn/wt/gzrobot/web/index')
    return{
      
    }
  }
}
</script>
<style lang="less" scoped>

</style>
